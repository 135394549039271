/* eslint-disable max-len */
import routes from 'utils/routes';

export const ACTIONS = [
  {
    id: 0,
    title: 'Raise a ticket',
    description:
      'Raise a ticket with your membership team for issues with facilities, bookings, IT or membership.',
    image: '/assets/images/Messages.svg',
    label: 'Raise a ticket',
    link: routes.main.help.raiseTicket,
  },
];

export const FORM_OPTIONS = {
  topic: [
    'Maintenance',
    'Cleaning',
    'Security',
    'IT',
    'Office Management',
    'App',
    'Door Access',
    'Other',
  ],
  location: [
    'Bermondsey',
    'Borough',
    'Camden',
    'Farringdon',
    'Fitzrovia',
    'Hammersmith',
    'Holborn',
    'Liverpool Street',
    'Old Street',
    'Soho',
    'St Cross Street',
    'Reading',
    'Manchester',
  ],
  urgency: ['Low', 'Medium', 'High', 'Emergency'],
  colors: ['#68CA3D', '#FEDA8F', '#FABE79', '#F76666'],
};

export const DEFAULT_VALUES = { topic: '', location: '', urgency: '' };
export const IMAGE_PICKER_CONFIG = {
  maxFiles: 1,
  multiple: false,
  maxSize: 10485760,
  accept: {
    'image/jpg': [],
    'image/jpeg': [],
    'image/png': [],
  },
  errors: {
    'too-many-files': 'You can upload up to 3 images.',
    'file-too-large': `Files shouldn't be larger than 10mb`,
  },
};

export const SUPPORT_CONTACT = '02034110089';
